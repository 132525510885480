@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

html {
  background: #03071e;
  font-family: 'Poppins', sans-serif;
  color: white;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  width: 100%;
  display: grid;
  place-items: center;
}

#root {
  width: 100%;
}

.box{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(#3a86ff, transparent, transparent, transparent, transparent);
  position: relative;
  display: grid;
  place-items: center;
  animation: loader 0.7s linear infinite;
}
.box::before,
.box::after{
  content: '';
  position: absolute;
}
.box::before{
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.box::after{
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 15px;
  height: 15px;
  background: #3a86ff;
  border-radius: 50%;
}
@keyframes loader {
  0%{
      transform: rotate(0);
      background: conic-gradient(#3a86ff, transparent, transparent, transparent, transparent);
  }
  25%{
      background: conic-gradient(#ffba00, transparent, transparent, transparent);
  }
  50%{
      background: conic-gradient(#c9184a, transparent, transparent);
  }
  100%{
      transform: rotate(-360deg);
      background: conic-gradient(#6E49FF, transparent, transparent);
  }
}

.help {
  align-items: center;
  border-radius: 50%;
  border: 3px solid #6E49FF;
  color: #6E49FF;
  font-size: 19px;
  font-weight: 600;
  height: 30px;
  text-align: center;
  width: 30px;
  cursor: pointer;
}

help.is-highlighted::before {
    background-color: white;
    border: 2px solid #6E49FF;
    border-radius: 50%;
    content: '';
    height: ems(10);
    position: absolute;
    right: -2px;
    top: -2px;
    width: ems(10);
}

.help:hover {
  animation-name: wiggle;
  animation-duration: .5s;
  animation-iteration-count: 1;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-15deg);
  }

  75% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.share-btn {
  display: inline-block;
  color: #ffffff;
  border: none;
  padding: 0.1em 0.6em;
  outline: none;
  text-align: center;
  font-size: 0.9em;
  margin: 0 0.2em;
}

.share-btn:focus,
.share-btn:hover {
  text-decoration: none;
  opacity: 0.8;
}

.share-btn:active {
  color: #e2e2e2;
}

.share-btn.twitter     { background: #55acee; }
.share-btn.google-plus { background: #dd4b39; }
.share-btn.facebook    { background: #3B5998; }
.share-btn.stumbleupon { background: #EB4823; }
.share-btn.reddit      { background: #ff5700; }
.share-btn.hackernews  { background: #ff6600; }
.share-btn.linkedin    { background: #4875B4; }
.share-btn.email       { background: #444444; }

.word {
  display: inline;
}

.word span {
  color: #6E49FF;
  /* position: relative; */
}

/* .word span::after {
  content: '';
  width: 8px;
  height: 100%;
  background: #ff5252;
  display: block;
  position: absolute;
  right: -10px;
  top: 0;
  animation: blink 0.5s ease infinite alternate-reverse;
} */

@keyframes blink {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}

.word.empty {
  visibility: hidden;
}

.word.empty span::after {
  visibility: visible;
  right: 0;
}